import emojiRegex from 'emoji-regex';
import sanitize from './sanitize';

export const isValidEmail = email => {
  const emailPattern =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line
  return emailPattern.test(email);
};

// Credit - https://css-tricks.com/snippets/javascript/unescape-html-in-js/
export const immersiveReaderHtmlDecoder = input => {
  const e = document.createElement('div');
  const sanitizedInput = sanitize.safe(input); // retains line breaks

  // convert <br> syntax to \n syntax that the IR supports
  const replacedLineBreaksText = sanitizedInput.replace(/<br \/>/g, '\n');

  // eslint-disable-next-line no-unsanitized/property
  e.innerHTML = replacedLineBreaksText;
  return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue;
};

export const openPopup = (url, title, w, h) => {
  // Credit - https://stackoverflow.com/questions/4068373/center-a-popup-window-on-screen
  const { innerWidth, innerHeight, screenTop, screenLeft } = window;
  const { clientWidth, clientHeight } = document.documentElement;

  const width = innerWidth || clientWidth;
  const height = innerHeight || clientHeight;

  const left = width / 2 - w / 2 + screenLeft;
  const top = height / 2 - h / 2 + screenTop;
  const newWindow = window.open(
    url,
    title,
    'scrollbars=yes, rel=noopener, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left,
  );

  // Puts focus on the newWindow
  if (newWindow && newWindow.focus) {
    newWindow.focus();
  }

  return newWindow;
};

export const emojiWrap = input => {
  const regex = emojiRegex();
  const text = sanitize.strict(input);
  let match;
  const emojis = [];
  const emojilength = [];
  const indexes = [];

  // eslint-disable-next-line
  while ((match = regex.exec(text))) {
    const emoji = match[0];
    emojis.push(emoji);
    emojilength.push(emoji.length);
    // eslint-disable-next-line
    const index = match['index'];
    // +2 to include the emoji in the substring
    indexes.push(index + emoji.length);
  }

  let newtext;
  const updated = [];

  Object.keys(emojis, indexes, emojilength).forEach(key => {
    // indexes[key]-2 to omit the emoji from the substring and add it custom to the end
    newtext =
      text.substring(indexes[key - 1], indexes[key] - emojilength[key]) +
      '<span class="emojiWrap">' +
      emojis[key] +
      ' </span>';
    newtext.replace(emojis[key], '');
    updated.push(newtext);
  });
  // push text after emoji if there is any
  const lastkey = indexes.length - 1;
  const lasttext = text.substring(indexes[lastkey]);
  updated.push(lasttext);
  const finaltext = updated.join('');

  return emojis.length > 0 ? finaltext : text;
};

export const isString = value => {
  return typeof value === 'string';
};

export const containsLowercase = value => {
  if (!value || typeof value !== 'string') return false;
  return value.toUpperCase() !== value;
};

export const containsUppercase = value => {
  if (!value || typeof value !== 'string') return false;
  return value.toLowerCase() !== value;
};

export const containsNumber = value => {
  if (!value || typeof value !== 'string') return false;
  return /\d/.test(value);
};

export const containsSymbol = value => {
  if (!value || typeof value !== 'string') return false;
  const symbols = /[!@#$%^&*]+/;
  return symbols.test(value);
};

export const isPasswordSecure = value => {
  let conditionsMet = 0;
  if (containsUppercase(value)) conditionsMet += 1;
  if (containsLowercase(value)) conditionsMet += 1;
  if (containsNumber(value)) conditionsMet += 1;
  if (containsSymbol(value)) conditionsMet += 1;
  if (conditionsMet >= 2 && value && value.length >= 8) return true;
  return false;
};

export const validateDiscoveryForm = formData => {
  if (formData) {
    const errors = {};

    if (!formData.title) errors.title = true;
    if (!formData.community || formData.community === 'empty') errors.community = true;
    if (!formData.subject || formData.subject === 'empty') errors.subject = true;
    if (formData.integration_notes && formData.integration_notes.length > 1024) errors.integration_notes = true;
    if (formData.text && formData.text.length > 3000) errors.description = true;

    return errors;
  }

  return false;
};
